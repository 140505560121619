import React, { useState, useContext } from "react";
import AccordionGroup from "../../base/AccordionGroup";
import VideoPlayer from "../../VideoPlayer";
import Button from "../../Button";
import { SelectOption } from "../../forms/SelectInput";
import { ValueType } from "react-select/src/types";
import WorkoutContext from "../../../context/WorkoutContext";
import useGetUserSubscription from "../../../hooks/useGetUserSubscription";
import { Link } from "gatsby";
import { COLOURS } from "../../../constants";
import InteractiveTooltip from "../../tooltips/InteractiveTooltip";
import ExerciseSelect from "../../ExerciseSelect";
import { useWindowSize } from "react-use";

type Key = number | string;

interface Meta {
  groupPosition: number;
  exercisePosition: number;
}

interface Props {
  id: string;
  resourceUrl: string;
  title: string;
  exerciseId: string;
  subtitle: string;
  handleToggleAccordion: (key: Key, state: boolean) => void;
  isPlaying: boolean;
  meta: Meta;
}

function ExerciseSegment(props: Props) {
  const [isEditable, setEditableState] = useState(false);
  const { updateWorkout } = useContext(WorkoutContext);
  const { width } = useWindowSize();
  const { data } = useGetUserSubscription();

  const isPremiumUser = data?.subscription?.active;

  const {
    id,
    resourceUrl,
    exerciseId,
    title,
    meta,
    subtitle,
    handleToggleAccordion,
    isPlaying
  } = props;

  function selectExercise(id: string) {
    return updateWorkout(id, meta);
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        {isEditable ? (
          <div
            style={{
              border: "1px solid white",
              height: "65px",
              margin: "8px 0",
              borderRadius: "2px"
            }}
          >
            <div
              style={{
                margin: 0,
                padding: "13px 24px 0"
              }}
            >
              <ExerciseSelect
                handleChange={(selectedValue: ValueType<SelectOption>) => {
                  if (!selectedValue) {
                    return selectExercise(exerciseId);
                  }

                  return selectExercise((selectedValue as SelectOption).value);
                }}
                defaultValue={{ label: title, value: exerciseId }}
              />
            </div>
          </div>
        ) : (
          <AccordionGroup
            accordionData={[
              {
                key: id,
                title,
                subtitle,
                handleToggle: handleToggleAccordion,
                content: resourceUrl ? (
                  <VideoPlayer url={resourceUrl} toPlay={isPlaying} />
                ) : (
                  <p>
                    We currently don't have a video available for this exercise.
                  </p>
                )
              }
            ]}
          />
        )}
      </div>
      <div
        style={{
          padding: "8px 0",
          display: "flex",
          alignItems: "flex-start"
        }}
      >
        {isPremiumUser ? (
          <div style={{ height: "67px" }}>
            <Button
              size={width < 600 ? "small" : "standard"}
              handleClick={() => setEditableState(!isEditable)}
            >
              {isEditable ? "Save" : "Edit"}
            </Button>
          </div>
        ) : (
          <InteractiveTooltip
            content={
              <p style={{ color: COLOURS.BLACK, margin: 0 }}>
                Looks like you're trying to use one of our premium features?
                Head to our{" "}
                <Link style={{ color: COLOURS.BLACK }} to="/pricing">
                  pricing page
                </Link>{" "}
                page to upgrade.
              </p>
            }
          >
            <Button testId="disabled-edit" disabled>
              Edit
            </Button>
          </InteractiveTooltip>
        )}
      </div>
    </div>
  );
}

export default ExerciseSegment;
