import React, { useContext } from "react";
import {
  WorkoutEvent,
  WorkoutContext as WorkoutMachineContext
} from "./state/workoutMachine";
import { Contraction } from "../../models";
import WorkoutIntro from "./WorkoutIntro/WorkoutIntro";
import Warmup from "./Warmup";
import Exercise from "./Exercise";
import Rest from "./Rest";
import WorkoutSummary from "./WorkoutSummary";
import WorkoutLayout from "../layouts/WorkoutLayout";
import WorkoutContext from "../../context/WorkoutContext";
import { OutboundLink } from "react-ga";
import { GroupType } from "../CustomWorkoutForm/models";

export interface Workout {
  id: string;
  meta: {
    title: string;
  };
  warmup: string[];
  workoutBlocks: WorkoutBlock[];
}

interface Props {
  workout: Workout;
}

interface WorkoutBlock {
  blockType: GroupType;
  restTime: number;
  setCount: number;
  exercises: ExerciseBlock[];
}

interface ExerciseBlock {
  exerciseId: string;
  title: string;
  contractionType: Contraction;
  repCount: number;
  repCounts: number[];
}

export interface WorkoutStateProps {
  send: (type: WorkoutEvent) => void;
  workout: Workout;
  context: WorkoutMachineContext;
}

function getComponents(
  props: WorkoutStateProps
): { [key: string]: React.ReactNode } {
  return {
    workoutIntro: <WorkoutIntro {...props} />,
    warmup: <Warmup {...props} />,
    exercise: <Exercise {...props} />,
    rest: <Rest {...props} />,
    workoutSummary: <WorkoutSummary {...props} />
  };
}

function Workout(props: Props) {
  const { state, send } = useContext(WorkoutContext);
  const { workout } = props;

  const components = getComponents({
    send,
    workout,
    context: state.context
  });

  const currentComponent = components[state.value as string];

  return (
    <WorkoutLayout exerciseId={state.context.currentExerciseId}>
      {currentComponent}
      <br />
      <p>
        Want to be featured in our Instagram stories? Just share your workout
        summary on Instagram and tag{" "}
        <OutboundLink
          eventLabel="From Workout Page To Instagram"
          target="_blank"
          rel="noopener noreferrer"
          to="https://instagram.com/caliskills.app"
        >
          Cali Skills
        </OutboundLink>
        .
      </p>
    </WorkoutLayout>
  );
}

export default Workout;
