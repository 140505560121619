import React, { useState, useEffect, useRef } from "react";
import ProgressInput from "../base/ProgressInput";
import useAuth from "../../hooks/useAuth";
import useProgressQuery from "../../hooks/useProgressQuery";
import useProgressMutation from "../../hooks/useProgressMutation";

const LOWER_LIMIT = 0;
const UPPER_LIMIT = 999;

interface Props {
  label: string;
  id: string;
  onUpdateProgress: (exerciseId: string, progress: number) => void;
  onSave: (exerciseId: string, progress: number) => void;
}

function UpdateExerciseProgress(props: Props) {
  const { id, label, onUpdateProgress, onSave } = props;
  const [inputValue, setInputValue] = useState<number | "">(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { getUserId, getAccessToken, getAuthenticationState } = useAuth();
  const [initialValue, setInitialValue] = useState(0);
  const [previouslyUpdatedValue, setPreviouslyUpdatedValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTooltipVisible, setTooltipVisibilityState] = useState(false);
  const [isRequestInFlight, setRequestInFlightState] = useState(false);
  const { data, status, error } = useProgressQuery();
  const updateProgressMutation = useProgressMutation();

  const loading = status === "loading";

  useEffect(() => {
    if (loading || error || !data) {
      return setInputValue(0);
    }

    if (!data.progress[id]) {
      return setInputValue(0);
    }

    const initialValue = data.progress[id];

    setInitialValue(initialValue);
    setInputValue(0);
    setTooltipVisibilityState(false);
  }, [data, loading, error, id]);

  useEffect(() => {
    return function () {
      if (timeoutRef.current) {
        global.clearTimeout(timeoutRef.current);
      }
    };
  });

  function updateProgress(skillId: string, progressAmount: number) {
    if (!getAuthenticationState()) return;

    const args = {
      userId: getUserId(),
      accessToken: getAccessToken(),
      skillId,
      progressAmount
    };

    onUpdateProgress(skillId, progressAmount);

    if (progressAmount <= initialValue) {
      setRequestInFlightState(false);
      setTooltipVisibility();
      return;
    }

    onSave(skillId, progressAmount);

    updateProgressMutation(args, {
      onError: () => {
        setErrorMessage("There was a problem saving your progress");
      },
      onSettled: () => {
        setRequestInFlightState(false);
        setTooltipVisibility();
      }
    });

    setRequestInFlightState(true);
  }

  function setTooltipVisibility() {
    timeoutRef.current = global.setTimeout(() => {
      setTooltipVisibilityState(false);
    }, 2500);

    setTooltipVisibilityState(true);
  }

  function handleChange(value: string) {
    if (!value) {
      return setInputValue("");
    }

    const intValue = Number(value);
    setErrorMessage("");
    const validatedValue = validateInput(intValue);
    return setInputValue(validatedValue);
  }

  function handleSubmit() {
    const intValue = Number(inputValue);
    setErrorMessage("");
    const validatedValue = validateInput(intValue);

    if (previouslyUpdatedValue === intValue) {
      return null;
    }

    setPreviouslyUpdatedValue(intValue);
    return updateProgress(id, validatedValue);
  }

  function validateInput(value: number) {
    if (value < LOWER_LIMIT) {
      setInputValue(LOWER_LIMIT);
      setErrorMessage(
        "With calisthenics anything is possible, except negative reps"
      );
      setTooltipVisibility();
      return LOWER_LIMIT;
    }

    if (value > UPPER_LIMIT) {
      setInputValue(UPPER_LIMIT);
      setErrorMessage("That's a lot of reps! We'll lock your progress at 999");
      setTooltipVisibility();
      return UPPER_LIMIT;
    }

    if (!Number.isInteger(value)) {
      setInputValue(Math.floor(value));
      setErrorMessage(
        "We appreciate the effort, but we can't count partial reps"
      );
      setTooltipVisibility();
      return Math.floor(value);
    }

    return value;
  }

  return (
    <ProgressInput
      id={id}
      label={label}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      value={inputValue}
      loading={isRequestInFlight}
      errorMessage={errorMessage}
      isTooltipVisible={isTooltipVisible}
      size="large"
    />
  );
}

export default UpdateExerciseProgress;
