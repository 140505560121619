import { SkillTheme } from "./bst_fork";

const defaultFont = `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif`;

export const COLOURS = {
  PURPLE: "#ad3fff",
  WHITE: "white",
  GREY_L: "#717375",
  GREY_M: "#35373b",
  GREY_D: "#2d2f33",
  GREY_XD: "#191b1f",
  BLACK: "#16181c",
  ORANGE: "#f54400"
};

export const caliSkillsTreeTheme: Partial<SkillTheme> = {
  treeBackgroundColor: COLOURS.BLACK,
  nodeBackgroundColor: COLOURS.BLACK,
  disabledTreeOpacity: 0.6,
  nodeMobileTextNodeWidth: "140px",
  nodeDesktopTextNodeWidth: "160px",
  primaryFont: defaultFont,
  headingFont: defaultFont,
  nodeAlternativeFontColor: COLOURS.ORANGE,
  nodeAltenativeActiveFontColor: COLOURS.WHITE,
  nodeAlternativeActiveBackgroundColor: `linear-gradient(to right, ${COLOURS.ORANGE} 0%, ${COLOURS.PURPLE} 100%)`,
  nodeActiveBackgroundColor: `linear-gradient(
    to right,
    ${COLOURS.PURPLE} 0%,
    ${COLOURS.PURPLE} 100%
  )`,
  nodeHoverBorderColor: `linear-gradient(to right, ${COLOURS.ORANGE} 0%, ${COLOURS.PURPLE} 100%)`
};
