import React, { useContext } from "react";
import TextPageLayout from "../components/layouts/TextPageLayout";
import Workout from "../components/Workout";
import { Link } from "gatsby";
import WorkoutContext from "../context/WorkoutContext";

function WorkoutPage() {
  const { workout } = useContext(WorkoutContext);

  if (!workout) {
    return (
      <TextPageLayout title="Your Workout">
        <p>
          Please choose your workout. You can create a custom workout on our{" "}
          <Link to="/workouts">Workout</Link> page
        </p>
      </TextPageLayout>
    );
  }

  return <Workout workout={workout} />;
}

export default WorkoutPage;
