import { CreatedWorkoutState } from "../models";

export interface UpdateWorkoutStateBody {
  userId: string;
  workoutId: string;
  workoutState: CreatedWorkoutState;
}

function updateWorkoutState(body: UpdateWorkoutStateBody, accessToken: string) {
  const { workoutId } = body;

  return fetch(
    `${process.env.GATSBY_SERVER_URL}/created-workouts/${workoutId}/update-state`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(body)
    }
  );
}

export default updateWorkoutState;
