import React from "react";
import Tippy from "@tippy.js/react";
import "../../styles/InteractiveTooltip.css";

interface Props {
  children: React.ReactElement<any>;
  content: React.ReactChild;
}

function InteractiveTooltip(props: Props) {
  const { children, content } = props;
  return (
    <Tippy interactive className="InteractiveTooltip" content={content}>
      {children}
    </Tippy>
  );
}

export default InteractiveTooltip;
