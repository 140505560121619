import { useMutation } from "react-query";
import ReactGA from "react-ga";
import updateUserProgress from "../api/updateUserProgress";
import { ProgressData } from "../models";
import { queryCache } from "react-query";

interface TVariables {
  userId: string;
  accessToken: string;
  skillId: string;
  progressAmount: number;
}

function useProgressMutation() {
  const [mutate] = useMutation<ProgressData, TVariables>(updateUserProgress, {
    onSuccess: (data, { userId, accessToken }) => {
      queryCache.setQueryData(["progress", { userId, accessToken }], data);

      ReactGA.event({
        category: "Progress Tracking",
        action: "successfully updated their exercise progress",
        label: "successfully updated their exercise progress"
      });
    },
    onError: () => {
      ReactGA.event({
        category: "Progress Tracking",
        action: "there was an error saving user progress",
        label: "unable to save progress"
      });
    }
  });

  return mutate;
}

export default useProgressMutation;
