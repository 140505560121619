import { useQuery } from "react-query";
import getUserProgress from "../api/getUserProgress";
import useAuth from "./useAuth";
import { ProgressData } from "../models";

type TKey = "progress";

interface TVariables {
  userId: string;
  accessToken: string;
}

function useProgressQuery() {
  const {
    getUserId,
    getAccessToken,
    getAuthenticationState,
    tokenRenewalComplete
  } = useAuth();

  const shouldFetch = getAuthenticationState() && tokenRenewalComplete;

  const userId = shouldFetch ? getUserId() : "";
  const accessToken = shouldFetch ? getAccessToken() : "";

  const { status, data, error } = useQuery<ProgressData, [TKey, TVariables]>(
    [
      "progress",
      {
        userId,
        accessToken
      }
    ],
    getUserProgress,
    { enabled: shouldFetch }
  );

  return {
    status,
    data,
    error
  };
}

export default useProgressQuery;
