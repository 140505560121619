import React from "react";
import SideSheet from "../base/SideSheet";
import VideoPlayer from "../VideoPlayer";
import exercises from "../../data/exercises/exercises";
import EXERCISE_KEYS from "../../data/exerciseKeys";
import ExercisePlaceholderImage from "../images/ExercisePlaceholderImage";

interface Props {
  exerciseId: string;
}

function ExerciseSheet(props: Props) {
  const { exerciseId } = props;

  const exercise = exercises[exerciseId as EXERCISE_KEYS];

  const headingImage = exercise.resourceUrl ? (
    <VideoPlayer url={exercise.resourceUrl} />
  ) : (
    <ExercisePlaceholderImage alt="this exercise currently doesn't have a video" />
  );

  return (
    <SideSheet headingImage={headingImage}>
      <h2>{exercise.title}</h2>
      {exercise.description}
    </SideSheet>
  );
}

export default ExerciseSheet;
