import React from "react";
import Countdown from "react-countdown";
import { WorkoutStateProps } from ".";
import ExerciseLayout from "./ExerciseLayout";
import Button from "../Button";
import Header from "../Header";

function getType(isLastSet: boolean, isLastWorkoutBlock: boolean) {
  if (!isLastSet) {
    return "NEXT_EXERCISE";
  }

  if (!isLastWorkoutBlock) {
    return "NEXT_WORKOUT_BLOCK";
  }

  return "FINISH_WORKOUT";
}

function getCopy(isLastSet: boolean, isLastWorkoutBlock: boolean) {
  if (!isLastSet) {
    return "Next Set";
  }

  if (!isLastWorkoutBlock) {
    return "Next Exercises";
  }

  return "Finish Workout";
}

function Rest(props: WorkoutStateProps) {
  const { workout, send, context } = props;
  const { currentSet, currentWorkoutBlockIndex } = context;
  const { workoutBlocks } = workout;
  const { setCount, restTime } = workoutBlocks[currentWorkoutBlockIndex];

  const isLastSet = currentSet === setCount - 1;
  const isLastWorkoutBlock =
    currentWorkoutBlockIndex === workoutBlocks.length - 1;

  const type = getType(isLastSet, isLastWorkoutBlock);
  const buttonCopy = getCopy(isLastSet, isLastWorkoutBlock);

  return (
    <ExerciseLayout
      mainContent={
        <Countdown
          date={Date.now() + restTime * 1000}
          onComplete={() => send({ type })}
          renderer={({ minutes, seconds }) => {
            return (
              <p
                aria-live="polite"
                style={{
                  fontSize: "120px",
                  margin: "0 auto",
                  textAlign: "center"
                }}
              >
                {minutes * 60 + seconds}
              </p>
            );
          }}
        />
      }
      textContent={<Header title="Rest" />}
      actionBar={[
        <div style={{ height: "80px" }}>
          <Button
            size="large"
            handleClick={() => {
              window.scroll(0, 0);
              return send({ type });
            }}
          >
            {buttonCopy}
          </Button>
        </div>
      ]}
    />
  );
}

export default Rest;
