import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img, { FixedObject } from "gatsby-image";

interface Props {
  alt: string;
}

interface StaticQueryData {
  file: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

function ExercisePlaceholderImage({ alt }: Props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "placeholder.png" }) {
            childImageSharp {
              fixed(width: 320, height: 240) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data: StaticQueryData) => {
        return <Img imgStyle={{ width: '320px', height: '240px' }} fixed={data.file.childImageSharp.fixed} alt={alt} />;
      }}
    />
  );
}

export default ExercisePlaceholderImage;
