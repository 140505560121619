import React from "react";
import classnames from "classnames";

interface Props {
  name: string;
  value: string | number;
  id: string;
  label: string;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  styles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
}

function TextInput(props: Props) {
  const {
    name,
    value,
    onBlur,
    onFocus,
    onChange,
    label,
    id,
    disabled,
    styles,
    containerStyles
  } = props;

  return (
    <div style={containerStyles} className="InputContainer">
      <label className="InputLabel" htmlFor={id}>
        {label}
      </label>
      <input
        style={styles}
        data-testid={id}
        className={classnames("Input", {
          "Input--disabled": disabled
        })}
        name={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        disabled={disabled}
        id={id}
        type="text"
      />
    </div>
  );
}

export default TextInput;
