import React from "react";
import EmojiRating, { Rate } from "../../base/EmojiRating";

const messages: Record<Rate, [string, string]> = {
  angry: [
    "We're sorry to hear that you didn't enjoy your workout",
    "How can we improve your next workout?"
  ],
  unhappy: [
    "We're sorry to hear that you didn't enjoy your workout",
    "How can we improve your next workout?"
  ],
  neutral: [
    "We want your workouts to bring a smile to your face",
    "What can we do to make this happen?"
  ],
  pleased: [
    "We're pleased that you had fun with your workout",
    "What did you enjoy the most?"
  ],
  delighted: [
    "We're pleased that you had a blast with your workout",
    "What did you enjoy the most?"
  ]
};

function RateYourWorkout() {
  return (
    <EmojiRating
      title="How did your workout go?"
      messages={messages}
      name="Workout"
      id="workout-feedback"
    />
  );
}

export default RateYourWorkout;
