import React, { useState } from "react";
import { WorkoutStateProps } from ".";
import Button from "../Button";
import AccordionGroup, { AccordionData } from "../base/AccordionGroup";
import VideoPlayer from "../VideoPlayer";

interface PausedPlayers {
  [key: number]: boolean;
}

function Warmup(props: WorkoutStateProps) {
  const { send } = props;
  const [pausedPlayers, setPausedPlayers] = useState<PausedPlayers>({});

  function handleToggle(key: string | number, state: boolean) {
    setPausedPlayers({
      ...pausedPlayers,
      [key]: state
    });
  }

  const warmup: AccordionData[] = [
    {
      key: 0,
      title: "Wrist Circles",
      subtitle: "30 seconds",
      handleToggle,
      content: (
        <VideoPlayer
          url={"https://youtu.be/mSZWSQSSEjE?t=50"}
          toPlay={pausedPlayers[0]}
        />
      )
    },
    {
      key: 1,
      title: "Wrist Pulses",
      subtitle: "30 seconds",
      handleToggle,
      content: (
        <VideoPlayer
          url={"https://youtu.be/mSZWSQSSEjE?t=50"}
          toPlay={pausedPlayers[1]}
        />
      )
    },
    {
      key: 2,
      title: "Deep Squat Hold",
      subtitle: "30 seconds",
      handleToggle,
      content: (
        <VideoPlayer
          url={"https://youtu.be/bRYIFLDbeXI?t=45"}
          toPlay={pausedPlayers[2]}
        />
      )
    }
  ];

  return (
    <>
      <h1>Your Warmup</h1>
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <div style={{ paddingBottom: "16px" }}>
          <AccordionGroup accordionData={warmup} />
        </div>
        <Button
          size="large"
          handleClick={() => send({ type: "NEXT_WORKOUT_BLOCK" })}
        >
          First Exercise
        </Button>
      </div>
    </>
  );
}

export default Warmup;
