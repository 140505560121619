import axios from "axios";

interface Args {
  userId: string;
  accessToken: string;
  skillId: string;
  progressAmount: number;
}

async function updateUserProgress(args: Args) {
  const { userId, accessToken, skillId, progressAmount } = args;

  const body = {
    skillId,
    progressAmount
  };

  const { data } = await axios.put(
    `${process.env.GATSBY_SERVER_URL}/progress/${userId}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return data;
}

export default updateUserProgress;
