import React from "react";
import classNames from "classnames";
import "../../styles/ExerciseLayout.css";

interface Props {
  mainContent: React.ReactNode;
  textContent: React.ReactNode;
  actionBar: React.ReactNode[];
}

function ExerciseLayout(props: Props) {
  return (
    <div className="ExerciseLayout">
      <div className="ExerciseLayout__main-content">{props.mainContent}</div>
      <div className="ExerciseLayout__text-content">{props.textContent}</div>
      <div
        className={classNames("ExerciseLayout__action-bar", {
          "ExerciseLayout__action-bar--single": props.actionBar.length === 1
        })}
      >
        {props.actionBar.map((button, i) => (
          <React.Fragment key={i}>{button}</React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default ExerciseLayout;
