import React, { useContext } from "react";
import { Link } from "gatsby";
import { queryCache } from "react-query";
import { WorkoutStateProps } from ".";
import "../../styles/WorkoutSummary.css";
import EXERCISE_KEYS from "../../data/exerciseKeys";
import exercisesData from "../../data/exercises/exercises";
import WorkoutContext from "../../context/WorkoutContext";
import { useEffectOnce } from "react-use";
import RateYourWorkout from "./WorkoutSummary/RateYourWorkout";
import updateWorkoutState, {
  UpdateWorkoutStateBody
} from "../../api/updateWorkoutState";
import useAuth from "../../hooks/useAuth";
import { CreatedWorkout, CreatedWorkoutState } from "../../models";

const COPY: { [key: string]: string } = {
  isometric: "s hold",
  isotonic: " rep(s)"
};

const TEMP_WORKOUT = {
  created_workout_id: "123",
  user_id: "123",
  workout_format: "",
  object_key: "",
  workout_state: "completed" as CreatedWorkoutState,
  created_at: new Date(),
  updated_at: new Date()
};

function WorkoutSummary(props: WorkoutStateProps) {
  const { workout, context } = props;
  const { workoutProgress, newMaxProgress } = context;
  const { endWorkout } = useContext(WorkoutContext);
  const { getAccessToken, getUserId } = useAuth();

  useEffectOnce(() => {
    const body: UpdateWorkoutStateBody = {
      userId: getUserId(),
      workoutId: workout.id,
      workoutState: "completed"
    };

    queryCache.setQueryData<CreatedWorkout[]>("workouts", oldData => {
      if (!oldData) return [TEMP_WORKOUT];
      return [...oldData, TEMP_WORKOUT];
    });

    updateWorkoutState(body, getAccessToken());
  });

  useEffectOnce(() => {
    return function () {
      return endWorkout();
    };
  });

  return (
    <>
      <h1>Nice Job, Workout Complete!</h1>
      <RateYourWorkout />
      <h3>Your Workout Summary</h3>
      <ul className="WorkoutSummary__list">
        {workout.workoutBlocks.map((block, i) => {
          const { exercises, setCount } = block;

          return (
            <React.Fragment key={i}>
              {exercises.map(exercise => {
                const { exerciseId, title, contractionType } = exercise;
                const { icon } = exercisesData[exerciseId as EXERCISE_KEYS];

                const contractionCopy = COPY[contractionType];
                const arrayOfFixedLength = Array(setCount).fill(0);
                const exerciseProgress = workoutProgress.find(
                  e => e.exerciseId === exerciseId
                );

                const progress = exerciseProgress?.progress ?? [];
                const maxRepRecord = newMaxProgress[exerciseId];

                return (
                  <li
                    className="WorkoutSummary__exercise-item"
                    key={exerciseId}
                  >
                    <div
                      style={{
                        width: "72px",
                        height: "96px",
                        paddingRight: "16px"
                      }}
                    >
                      <img
                        style={{
                          width: "72px",
                          height: "96px"
                        }}
                        alt="V-Sit"
                        src={icon}
                      />
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <p className="WorkoutSummary__exercise-item-title">
                        {title}
                      </p>
                      <div className="WorkoutSummary__exercise-result-container">
                        {arrayOfFixedLength.map((_, i) => {
                          return (
                            <span
                              className="WorkoutSummary__exercise-result"
                              key={i}
                            >
                              Set {i + 1}: {progress[i]}
                              {contractionCopy}
                            </span>
                          );
                        })}
                      </div>
                      {maxRepRecord && (
                        <p>
                          <span style={{ fontWeight: 700 }}>
                            Nice! You broke your record for this exercise.
                          </span>
                          <br />
                          New max: {maxRepRecord}
                          {contractionCopy}.
                        </p>
                      )}
                    </div>
                  </li>
                );
              })}
            </React.Fragment>
          );
        })}
      </ul>
      <h2>
        You can jump back home <Link to="/home">here</Link>.
      </h2>
    </>
  );
}

export default WorkoutSummary;
