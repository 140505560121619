import React from "react";
import classNames from "classnames";
import "../../styles/UpdateProgressForm.css";
import ControlledTooltip from "../tooltips/ControlledTooltip";

interface Props {
  id: string;
  label: string;
  handleSubmit: () => void;
  handleChange: (value: string) => void;
  loading: boolean;
  value: number | "";
  isTooltipVisible: boolean;
  size?: "large" | "standard";
  errorMessage: string;
}

function ProgressInput(props: Props) {
  const {
    id,
    label,
    value,
    handleSubmit,
    loading,
    handleChange,
    isTooltipVisible,
    size = "standard",
    errorMessage
  } = props;

  return (
    <form
      data-testid="current-progress-form"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className={`UpdateProgressForm UpdateProgressForm--${size}`}>
        <label className="UpdateProgressForm__label" htmlFor={`progress-${id}`}>
          {label}
        </label>
        <ControlledTooltip
          isVisible={isTooltipVisible}
          placement="top"
          content={
            <p style={{ margin: 0, zIndex: 1, fontSize: "12px" }}>
              {errorMessage ? errorMessage : "Your progress has been saved"}
            </p>
          }
        >
          <div style={{ display: "flex" }}>
            <input
              className={`UpdateProgressForm__input UpdateProgressForm__input--${size}`}
              type="number"
              onChange={e => handleChange(e.target.value)}
              id={`progress-${id}`}
              name="max-progress"
              value={value}
            />
            <button
              type="submit"
              className={classNames(
                `UpdateProgressForm__button hover-sheet UpdateProgressForm__button--${size}`,
                {
                  "UpdateProgressForm__button--loading": loading
                }
              )}
            >
              save
            </button>
          </div>
        </ControlledTooltip>
      </div>
    </form>
  );
}

export default ProgressInput;
