import React, { useState } from "react";
import { WorkoutStateProps } from "..";
import { navigate } from "gatsby";
import Button from "../../Button";
import exercisesData from "../../../data/exercises/exercises";
import "../../../styles/WorkoutIntro.css";
import EXERCISE_KEYS from "../../../data/exerciseKeys";
import ExerciseSegment from "./ExerciseSegment";
import { useEffectOnce } from "react-use";
import updateWorkoutState, {
  UpdateWorkoutStateBody
} from "../../../api/updateWorkoutState";
import useAuth from "../../../hooks/useAuth";

const COPY: { [key: string]: string } = {
  isometric: "s hold",
  isotonic: " rep(s)"
};

type Key = number | string;

type PausedPlayers = {
  [key in Key]: boolean;
};

function WorkoutIntro(props: WorkoutStateProps) {
  const { workoutBlocks, id } = props.workout;
  const [pausedPlayers, setPausedPlayers] = useState<PausedPlayers>({});
  const { getAccessToken, getUserId } = useAuth();

  function handleToggle(key: number | string, state: boolean) {
    setPausedPlayers({
      ...pausedPlayers,
      [key]: state
    });
  }

  useEffectOnce(() => {
    const body: UpdateWorkoutStateBody = {
      userId: getUserId(),
      workoutId: id,
      workoutState: "started"
    };

    updateWorkoutState(body, getAccessToken());
  });

  return (
    <>
      <h1>Your Workout</h1>
      <ul className="WorkoutIntro__list">
        {workoutBlocks.map((block, i) => {
          const { exercises, restTime, setCount, blockType } = block;

          return (
            <React.Fragment key={i}>
              {exercises.map((exercise, j) => {
                const {
                  exerciseId,
                  title,
                  repCount,
                  contractionType
                } = exercise;

                const pyramidCopy = blockType === "pyramid" ? "up to " : "";

                const contractionCopy = COPY[contractionType];
                const subtitle = `${setCount} set(s) x ${pyramidCopy}${repCount}${contractionCopy}`;
                const { resourceUrl } = exercisesData[
                  exerciseId as EXERCISE_KEYS
                ];

                const key = `${exerciseId}-${i}-${j}`;

                return (
                  <ExerciseSegment
                    key={key}
                    id={key}
                    exerciseId={exerciseId}
                    resourceUrl={resourceUrl}
                    title={title}
                    subtitle={subtitle}
                    handleToggleAccordion={handleToggle}
                    isPlaying={pausedPlayers[key]}
                    meta={{ groupPosition: i, exercisePosition: j }}
                  />
                );
              })}

              <br />
              <li>Rest: {restTime} second(s)</li>
              <br />
            </React.Fragment>
          );
        })}
      </ul>
      <div className="WorkoutIntro__action-bar">
        <Button handleClick={() => navigate("/workouts")}>Back</Button>
        <Button handleClick={() => props.send({ type: "NEXT_WARMUP" })}>
          Start Warmup
        </Button>
      </div>
    </>
  );
}

export default WorkoutIntro;
