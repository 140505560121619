import React from "react";
import Player from "react-player";

interface Props {
  url: string;
  toPlay?: boolean;
}

function VideoPlayer({ url, toPlay }: Props) {
  return (
    <div style={{ margin: "8px 0", height: "240px" }}>
      <Player
        controls
        data-testid="video-player"
        width="100%"
        height="100%"
        url={url}
        light
        playing={toPlay}
      />
    </div>
  );
}

export default VideoPlayer;
