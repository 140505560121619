import React, { useState, useEffect } from "react";
import { WorkoutStateProps } from ".";
import Button from "../Button";
import ExerciseLayout from "./ExerciseLayout";
import UpdateExerciseProgress from "../updateProgress/UpdateExerciseProgress";
import Header from "../Header";
import Metronome from "../Metronome";
import exercises from "../../data/exercises/exercises";
import EXERCISE_KEYS from "../../data/exerciseKeys";

const COPY: { [key: string]: string } = {
  isometric: "s hold",
  isotonic: " rep(s)"
};

function Exercise(props: WorkoutStateProps) {
  const { workout, send, context } = props;
  const { currentWorkoutBlockIndex, currentSet } = context;
  const { exercises: currentExerciseBlock, setCount } = workout.workoutBlocks[
    currentWorkoutBlockIndex
  ];

  const [currentExerciseIndex, setNextExerciseIndex] = useState(0);
  const currentExercise = currentExerciseBlock[currentExerciseIndex];
  const { repCounts, title, contractionType, exerciseId } = currentExercise;
  const { icon } = exercises[exerciseId as EXERCISE_KEYS];
  const contractionTypeCopy = COPY[contractionType];

  useEffect(() => {
    const defaultProgress = Array(setCount).fill(0);
    send({ type: "INIT_EXERCISE", value: { exerciseId, defaultProgress } });
  }, [exerciseId, send, setCount]);

  const hasNextExercise =
    currentExerciseIndex < currentExerciseBlock.length - 1;

  const type = hasNextExercise ? "NEXT_EXERCISE" : "START_REST";
  const buttonCopy = hasNextExercise ? "Next: Exercise" : "Next: Rest";

  const label =
    contractionType === "isotonic"
      ? "how many reps did you do?"
      : "how long did you hold it for? (s)";

  function updateProgress(exerciseId: string, progress: number) {
    return send({
      type: "UPDATE_PROGRESS",
      value: {
        exerciseId,
        progress
      }
    });
  }

  function setMaxProgress(exerciseId: string, progress: number) {
    return send({
      type: "UPDATE_MAX_PROGRESS",
      value: {
        exerciseId,
        progress
      }
    });
  }

  return (
    <ExerciseLayout
      key={exerciseId}
      mainContent={
        <img
          style={{ width: "100%", maxHeight: "256px" }}
          alt="Person performing exercise."
          src={icon}
        />
      }
      textContent={
        <>
          <Header
            title={title}
            hasSideSheet
            sideSheetLabelText={`About the ${title}`}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: "20px", margin: "16px 0" }}>
              Set {currentSet + 1}
              <br />
              {`Perform ${repCounts[currentSet]}${contractionTypeCopy}`}
            </p>
            <Metronome />
          </div>
        </>
      }
      actionBar={[
        <UpdateExerciseProgress
          onUpdateProgress={updateProgress}
          onSave={setMaxProgress}
          id={exerciseId}
          label={label}
        />,
        <div style={{ height: "80px" }}>
          <Button
            size="large"
            handleClick={() => {
              window.scroll(0, 0);
              setNextExerciseIndex(currentExerciseIndex + 1);
              return send({ type });
            }}
          >
            {buttonCopy}
          </Button>
        </div>
      ]}
    />
  );
}

export default Exercise;
