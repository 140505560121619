/* istanbul ignore file */
import React, { useRef, useState, useEffect } from "react";
// @ts-ignore
import MetronomeWorker from "../workers/metronome.worker";
import Button from "./Button";

const metronomeWorker = typeof window === "object" && new MetronomeWorker();

const TEMPO = 60;
const BEAT_FREQUENCY = 880;
const BEAT_VOLUME = 1;
const TICKS_PER_BEAT = 4;
const BEATS_PER_MEASURE = 1;
const SCHEDULE_AHEAD_TIME = 0.1;
const SECONDS_IN_MINUTE = 60;
const NOTE_LENGTH = 0.05;

function Metronome() {
  const audioContextRef = useRef<AudioContext | null>(null);
  const nextNoteTimeRef = useRef(0);
  const currentBeatRef = useRef(0);
  const [isPlaying, setPlayingState] = useState(false);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext ||
      // @ts-ignore
      window.webkitAudioContext)();

    function runScheduler() {
      while (
        nextNoteTimeRef.current <
        audioContextRef.current!.currentTime + SCHEDULE_AHEAD_TIME
      ) {
        tick(currentBeatRef.current, nextNoteTimeRef.current);

        const secondsPerBeat = SECONDS_IN_MINUTE / TEMPO;

        nextNoteTimeRef.current +=
          (BEATS_PER_MEASURE / TICKS_PER_BEAT) * secondsPerBeat;

        currentBeatRef.current++;

        if (currentBeatRef.current === TICKS_PER_BEAT) {
          currentBeatRef.current = 0;
        }
      }
    }

    metronomeWorker.onmessage = (event: { data: string }) => {
      if (event.data === "TICK") {
        runScheduler();
      }
    };

    return function cleanup() {
      metronomeWorker.postMessage({
        action: "STOP"
      });
    };
  }, []);

  function tick(beat: number, time: number) {
    const isFirstBeat = beat === 0;

    let playTick = false;

    const osc = audioContextRef.current!.createOscillator();
    const gainNode = audioContextRef.current!.createGain();
    osc.connect(gainNode);
    gainNode.connect(audioContextRef.current!.destination);

    if (isFirstBeat) {
      playTick = true;
      osc.frequency.setTargetAtTime(
        BEAT_FREQUENCY,
        audioContextRef.current!.currentTime,
        0.0001
      );
      gainNode.gain.setTargetAtTime(
        BEAT_VOLUME,
        audioContextRef.current!.currentTime,
        0.001
      );
    }

    if (playTick) {
      osc.start(time);
      osc.stop(time + NOTE_LENGTH);
    }
  }

  function start() {
    currentBeatRef.current = 0;
    nextNoteTimeRef.current = audioContextRef.current!.currentTime;

    metronomeWorker.postMessage({
      action: "START"
    });

    setPlayingState(true);
  }

  function stop() {
    metronomeWorker.postMessage({
      action: "STOP"
    });

    setPlayingState(false);
  }

  function onPlay() {
    isPlaying ? stop() : start();
  }

  return (
    <div style={{ height: "100%", margin: "16px 0" }}>
      <p style={{ color: "white", margin: 0 }}>metronome:</p>
      <Button
        handleClick={() => {
          audioContextRef.current!.resume();
          return onPlay();
        }}
      >
        {isPlaying ? "Pause" : "Play"}
      </Button>
    </div>
  );
}

export default Metronome;
