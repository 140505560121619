import React from "react";
import HSeparator from "../HSeparator";
import PageContent from "./PageContent";
import Footer from "../Footer";
import NavRoutes from "../NavRoutes";
import TextPageLayout from "./TextPageLayout";
import ExerciseSheet from "../ExerciseSheet/ExerciseSheet";
import "../../styles/TextPageLayout.css";

interface Props {
  title?: string;
  children: React.ReactNode;
  exerciseId: string | null;
}

function WorkoutLayout(props: Props) {
  const { exerciseId, children, title } = props;

  if (!exerciseId) {
    return <TextPageLayout children={children} title={title} />;
  }

  return (
    <>
      <NavRoutes />
      <div className="TextPageLayout">
        <HSeparator />
        <PageContent pageTitle={title} hasSideSheet>
          {children}
          <ExerciseSheet exerciseId={exerciseId} />
        </PageContent>
      </div>
      <Footer hasSideSheet />
    </>
  );
}

export default WorkoutLayout;
